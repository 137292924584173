<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card
      no-body
    >
      <b-card-body>
        <p>
          รูปแบบการส่งข้อความ
        </p>

        <div class="demo-inline-spacing mt-n1">
          <b-form-radio
            v-model="type_sms"
            name="some-radios"
            value="1"
          >
            ส่งข้อความแบบปกติ
          </b-form-radio>
          <b-form-radio
            v-model="type_sms"
            name="some-radios"
            value="2"
          >
            ส่งข้อความจากไฟล์
          </b-form-radio>
        </div>

        <hr>

        <div>
          <p>
            template
          </p>

          <b-form-select
            v-model="selected"
            :options="options"
            class="mb-2"
          />
        </div>

        <hr>

        <div>
          <p>
            รายละเอียดข้อความ
          </p>

          <div class="d-flex align-items-center justify-content-between">
            <label for="">
              ข้อความ
            </label>

            <a :class="`${ message.length > 70 ? 'text-danger' : '' }`">
              {{ message.length }} / 70
            </a>
          </div>

          <b-form-textarea
            v-model="message"
            placeholder="ข้อความ"
            rows="5"
            @input="limitText"
          />

          <b-alert
            variant="primary"
            class="mt-1"
            show
          >
            <div class="alert-body">
              <span>
                เครดิตที่ใช้ <b>0 เครดิต</b>
              </span>
            </div>
          </b-alert>
        </div>

      </b-card-body>
    </b-card>

    <p>
      เบอร์ที่ต้องการส่ง
    </p>
    <b-card
      no-body
    >
      <b-card-body>
        <p>
          เบอร์โทรศัพท์
        </p>

        <div class="row">
          <div class="col-md-6">
            <b-form-textarea
              v-model="phone_number"
              placeholder="ข้อความ"
              rows="10"
            />
          </div>

          <div class="col-md-6">
            <div class="btn btn-outline-primary rounded-pill mb-50">
              เลือกจากกลุ่ม
            </div>
            <br>
            <div class="btn btn-outline-primary rounded-pill mb-50">
              เลือกจากรายชื่อทั้งหมด
            </div>
            <br>
            <div class="btn btn-outline-primary rounded-pill mb-50">
              อิมพอร์ตจากไฟล์
            </div>
            <br>
            <div class="btn btn-outline-danger rounded-pill mb-50">
              ล้างข้อมูล
            </div>

          </div>

          <div class="col-md-12">
            <b-alert
              variant="primary"
              class="mt-1"
              show
            >
              <div class="alert-body">
                <span>
                  ผู้รับทั้งหมด <b>0 เบอร์</b>
                </span>
              </div>
            </b-alert>
          </div>
        </div>
      </b-card-body>
    </b-card>

    <b-modal
      id="modal-1"
      title="เติมเครดิต"
      ok-title="เติมเครดิต"
      @ok="submit"
    >
      <b-form-group>
        <v-select
          v-model="member"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="username"
          :options="optionsMember"
        />
      </b-form-group>
      <b-form-group
        label="จำนวนเงินที่ฝาก"
        label-for="vi-first-name"
      >
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <!-- <feather-icon icon="CheckSquareIcon" /> -->
          </b-input-group-prepend>
          <b-form-input
            id="vi-first-name"
            v-model="amount"
            placeholder=""
          />
        </b-input-group>
      </b-form-group>
    </b-modal>
  </b-overlay>
</template>

<script>
import vSelect from 'vue-select'
import {
  BFormRadio, BModal, BCard, BFormGroup, BFormSelect, BInputGroup, BFormInput, BCardBody, VBToggle, BOverlay, BIconController, BFormTextarea, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    vSelect,
    BFormRadio,
    BModal,
    BCard,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormInput,
    BCardBody,
    BOverlay,
    BIconController,
    BFormTextarea,
    BAlert,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      type_sms: 1,
      message: '',
      phone_number: {},
    }
  },
  watch: {
    message(newVal) {
      if (newVal.length > 70) {
        this.message = newVal.substring(0, 70)
      }
    },
  },
  mounted() {
  },
  methods: {
    addData() {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = {}
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = data
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    limitText() {
      if (this.message.length > 70) {
        alert('ข้อความต้องไม่เกิน 70 ตัวอักษร')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
